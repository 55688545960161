<template>
  <div>
    <h1 class="mb-4">
      Report: All Projects
    </h1>
    <v-row>
      <v-col cols="4">
        <v-card outlined min-height="300px">
          <v-card-title>
            Overview
          </v-card-title>
          <p class="metric px-4 py-2 ma-0">
            <span class="label">Projects:</span>{{ 3 }}
          </p>
          <p class="metric px-4 py-2 ma-0">
            <span class="label">Total investment:</span>{{ 3 * 935000 | toCurrency }}
          </p>
          <p class="metric px-4 py-2 ma-0">
            <span class="label">Total depreciation:</span>{{ 3 * 778650 | toCurrency }}
          </p>
          <p class="metric px-4 py-2 ma-0">
            <span class="label">Total tax credits:</span>{{ 3 * 232700 | toCurrency }}
          </p>
          <p class="metric px-4 py-2 ma-0">
            <span class="label">Total cash flow:</span>{{ 3 * 525210 | toCurrency }}
          </p>
          <p class="metric px-4 py-2 ma-0">
            <span class="label">Average IRR:</span>{{ '11.5%' }}
          </p>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card outlined min-height="300px">
          <highcharts :options="quarterlyRevenueChartOptions" style="height: 300px"></highcharts>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-card outlined min-height="300px">
          <highcharts :options="revenueSplitChartOptions" style="height: 300px"></highcharts>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card outlined min-height="300px">
          <highcharts :options="cumulativeRevenueChartOptions" style="height: 300px"></highcharts>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import colors from 'vuetify/lib/util/colors'

export default {
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      project: {},
      revenueSplitChartOptions: {
        chart: {
          backgroundColor: null,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
        },
        title: {
          text: 'Revenue Split',
          style: {
            color: '#ffffffde',
          },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>',
        },
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
              format: '<b>{point.name}</b>: {point.percentage:.2f}%',
            },
            showInLegend: true,
          },
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          itemMarginTop: 15,
          itemStyle: {
            color: '#ffffffde',
          },
          itemHoverStyle: {
            color: '#ffffff',
          },
        },
        colors: [
          colors.blue.lighten2,
          colors.green.lighten2,
          colors.yellow.lighten2,
          colors.orange.lighten2,
          colors.red.lighten2,
          colors.purple.lighten2,
        ],
        series: [
          {
            name: 'Amount',
            colorByPoint: true,
            innerSize: '50%',
            data: [
              {
                name: 'Cash Flow',
                y: 525210,
              },
              {
                name: 'MACRS Depreciation',
                y: 778650,
              },
              {
                name: 'Investment Tax Credit',
                y: 232700,
              },
            ],
          },
        ],
      },
      quarterlyRevenueChartOptions: {
        chart: {
          backgroundColor: null,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'column',
        },
        title: {
          text: 'Quarterly Revenue',
          style: {
            color: '#ffffffde',
          },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>${point.y:,.0f}<br/>Total: ${point.stackTotal:,.0f}</b>',
        },
        xAxis: {
          type: 'datetime',
          ordinal: false,
          title: {
            text: 'Date',
            style: {
              color: '#ffffffde',
            },
          },
          labels: {
            style: {
              color: '#ffffffde',
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Amount ($)',
            style: {
              color: '#ffffffde',
            },
          },
          labels: {
            style: {
              color: '#ffffffde',
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            shadow: false,
            stacking: 'normal',
          },
        },
        legend: {
          enabled: false,
        },
        colors: [
          colors.blue.lighten2,
          colors.green.lighten2,
          colors.yellow.lighten2,
          colors.orange.lighten2,
          colors.red.lighten2,
          colors.purple.lighten2,
        ],
        series: [
          {
            name: 'Cash Flow',
            data: [
              [new Date(2022, 3, 28, 2).getTime(), 3 * 17744.6],
              [new Date(2022, 6, 28, 2).getTime(), 3 * 31053.05],
              [new Date(2022, 9, 28, 2).getTime(), 3 * 35489.2],
              [new Date(2022, 12, 28, 2).getTime(), 3 * 4436.15],
              [new Date(2023, 3, 28, 2).getTime(), 3 * 17620.36875],
              [new Date(2023, 6, 28, 2).getTime(), 3 * 30835.64531],
              [new Date(2023, 9, 28, 2).getTime(), 3 * 35240.7375],
              [new Date(2023, 12, 28, 2).getTime(), 3 * 4405.092188],
              [new Date(2024, 3, 28, 2).getTime(), 3 * 17505.10839],
              [new Date(2024, 6, 28, 2).getTime(), 3 * 30633.93969],
              [new Date(2024, 9, 28, 2).getTime(), 3 * 35010.21678],
              [new Date(2024, 12, 28, 2).getTime(), 3 * 4376.277098],
              [new Date(2025, 3, 28, 2).getTime(), 3 * 17398.59247],
              [new Date(2025, 6, 28, 2).getTime(), 3 * 30447.53682],
              [new Date(2025, 9, 28, 2).getTime(), 3 * 34797.18494],
              [new Date(2025, 12, 28, 2).getTime(), 3 * 4349.648118],
              [new Date(2026, 3, 28, 2).getTime(), 3 * 17300.6009],
              [new Date(2026, 6, 28, 2).getTime(), 3 * 30276.05157],
              [new Date(2026, 9, 28, 2).getTime(), 3 * 34601.20179],
              [new Date(2026, 12, 28, 2).getTime(), 3 * 4325.150224],
              [new Date(2027, 3, 28, 2).getTime(), 3 * 17210.91972],
              [new Date(2027, 6, 28, 2).getTime(), 3 * 30119.10951],
              [new Date(2027, 9, 28, 2).getTime(), 3 * 34421.83944],
              [new Date(2027, 12, 28, 2).getTime(), 3 * 4302.72993],
              [new Date(2028, 3, 28, 2).getTime(), 3 * 17129.34091],
              [new Date(2028, 6, 28, 2).getTime(), 3 * 29976.3466],
              [new Date(2028, 9, 28, 2).getTime(), 3 * 34258.68183],
              [new Date(2028, 12, 28, 2).getTime(), 3 * 4282.335228],
              [new Date(2029, 3, 28, 2).getTime(), 3 * 17055.66215],
              [new Date(2029, 6, 28, 2).getTime(), 3 * 29847.40877],
              [new Date(2029, 9, 28, 2).getTime(), 3 * 34111.32431],
              [new Date(2029, 12, 28, 2).getTime(), 3 * 4263.915539],
              [new Date(2030, 3, 28, 2).getTime(), 3 * 16989.68662],
              [new Date(2030, 6, 28, 2).getTime(), 3 * 29731.95159],
              [new Date(2030, 9, 28, 2).getTime(), 3 * 33979.37325],
              [new Date(2030, 12, 28, 2).getTime(), 3 * 4247.421656],
              [new Date(2031, 3, 28, 2).getTime(), 3 * 16931.22278],
              [new Date(2031, 6, 28, 2).getTime(), 3 * 29629.63987],
              [new Date(2031, 9, 28, 2).getTime(), 3 * 33862.44557],
              [new Date(2031, 12, 28, 2).getTime(), 3 * 4232.805696],
            ],
          },
          {
            name: 'MACRS Depreciation',
            data: [
              [new Date(2022, 12, 28, 2).getTime(), 3 * 155730],
              [new Date(2023, 12, 28, 2).getTime(), 3 * 249168],
              [new Date(2024, 12, 28, 2).getTime(), 3 * 149500.8],
              [new Date(2025, 12, 28, 2).getTime(), 3 * 89700.48],
              [new Date(2026, 12, 28, 2).getTime(), 3 * 89700.48],
              [new Date(2027, 12, 28, 2).getTime(), 3 * 44850.24],
              [new Date(2028, 12, 28, 2).getTime(), 3 * 44850.24],
              [new Date(2029, 12, 28, 2).getTime(), 3 * 44850.24],
            ],
          },
          {
            name: 'Investment Tax Credit',
            data: [
              [new Date(2022, 12, 28, 2).getTime(), 3 * 232700],
              [new Date(2023, 12, 28, 2).getTime(), 3 * 232700],
              [new Date(2024, 12, 28, 2).getTime(), 3 * 232700],
              [new Date(2025, 12, 28, 2).getTime(), 3 * 232700],
              [new Date(2026, 12, 28, 2).getTime(), 3 * 232700],
            ],
          },
        ],
      },
      cumulativeRevenueChartOptions: {
        chart: {
          backgroundColor: null,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'column',
        },
        title: {
          text: 'Cumulative Revenue',
          style: {
            color: '#ffffffde',
          },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>${point.y:,.0f}<br/>Total: ${point.stackTotal:,.0f}</b>',
        },
        xAxis: {
          type: 'datetime',
          ordinal: false,
          title: {
            text: 'Date',
            style: {
              color: '#ffffffde',
            },
          },
          labels: {
            style: {
              color: '#ffffffde',
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Total ($)',
            style: {
              color: '#ffffffde',
            },
          },
          labels: {
            style: {
              color: '#ffffffde',
            },
          },
          // plotLines: [
          //   {
          //     value: 935000,
          //     color: 'red',
          //     dashStyle: 'shortdash',
          //     width: 2,
          //     label: {
          //       text: 'Investment',
          //       style: {
          //         color: '#ffffffde',
          //       },
          //     },
          //   },
          // ],
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            shadow: false,
            stacking: 'normal',
          },
        },
        legend: {
          enabled: false,
        },
        colors: [
          colors.blue.lighten2,
          colors.green.lighten2,
          colors.yellow.lighten2,
          colors.orange.lighten2,
          colors.red.lighten2,
          colors.purple.lighten2,
        ],
        series: [
          {
            name: 'Cash Flow',
            data: [
              [new Date(2022, 3, 28, 2).getTime(), 3 * 17212.262],
              [new Date(2022, 6, 28, 2).getTime(), 3 * 47333.7205],
              [new Date(2022, 9, 28, 2).getTime(), 3 * 81758.2445],
              [new Date(2022, 12, 28, 2).getTime(), 3 * 86061.31],
              [new Date(2023, 3, 28, 2).getTime(), 3 * 103153.0677],
              [new Date(2023, 6, 28, 2).getTime(), 3 * 133063.6436],
              [new Date(2023, 9, 28, 2).getTime(), 3 * 167247.159],
              [new Date(2023, 12, 28, 2).getTime(), 3 * 171520.0984],
              [new Date(2024, 3, 28, 2).getTime(), 3 * 188500.0536],
              [new Date(2024, 6, 28, 2).getTime(), 3 * 218214.9751],
              [new Date(2024, 9, 28, 2).getTime(), 3 * 252174.8854],
              [new Date(2024, 12, 28, 2).getTime(), 3 * 256419.8741],
              [new Date(2025, 3, 28, 2).getTime(), 3 * 273296.5088],
              [new Date(2025, 6, 28, 2).getTime(), 3 * 302830.6196],
              [new Date(2025, 9, 28, 2).getTime(), 3 * 336583.8889],
              [new Date(2025, 12, 28, 2).getTime(), 3 * 340803.0476],
              [new Date(2026, 3, 28, 2).getTime(), 3 * 357584.6305],
              [new Date(2026, 6, 28, 2).getTime(), 3 * 386952.4005],
              [new Date(2026, 9, 28, 2).getTime(), 3 * 420515.5663],
              [new Date(2026, 12, 28, 2).getTime(), 3 * 424710.962],
              [new Date(2027, 3, 28, 2).getTime(), 3 * 441405.5541],
              [new Date(2027, 6, 28, 2).getTime(), 3 * 470621.0903],
              [new Date(2027, 9, 28, 2).getTime(), 3 * 504010.2746],
              [new Date(2027, 12, 28, 2).getTime(), 3 * 508183.9226],
              [new Date(2028, 3, 28, 2).getTime(), 3 * 509040.3897],
              [new Date(2028, 6, 28, 2).getTime(), 3 * 510539.207],
              [new Date(2028, 9, 28, 2).getTime(), 3 * 512252.1411],
              [new Date(2028, 12, 28, 2).getTime(), 3 * 512466.2578],
              [new Date(2029, 3, 28, 2).getTime(), 3 * 513319.0409],
              [new Date(2029, 6, 28, 2).getTime(), 3 * 514811.4114],
              [new Date(2029, 9, 28, 2).getTime(), 3 * 516516.9776],
              [new Date(2029, 12, 28, 2).getTime(), 3 * 516730.1734],
              [new Date(2030, 3, 28, 2).getTime(), 3 * 517579.6577],
              [new Date(2030, 6, 28, 2).getTime(), 3 * 519066.2553],
              [new Date(2030, 9, 28, 2).getTime(), 3 * 520765.2239],
              [new Date(2030, 12, 28, 2).getTime(), 3 * 520977.595],
              [new Date(2031, 3, 28, 2).getTime(), 3 * 521824.1562],
              [new Date(2031, 6, 28, 2).getTime(), 3 * 523305.6382],
              [new Date(2031, 9, 28, 2).getTime(), 3 * 524998.7604],
              [new Date(2031, 12, 28, 2).getTime(), 3 * 525210.4007],
            ],
          },
          {
            name: 'MACRS Depreciation',
            data: [
              [new Date(2022, 3, 28, 2).getTime(), 3 * 0],
              [new Date(2022, 6, 28, 2).getTime(), 3 * 0],
              [new Date(2022, 9, 28, 2).getTime(), 3 * 0],
              [new Date(2022, 12, 28, 2).getTime(), 3 * 155730],
              [new Date(2023, 3, 28, 2).getTime(), 3 * 155730],
              [new Date(2023, 6, 28, 2).getTime(), 3 * 155730],
              [new Date(2023, 9, 28, 2).getTime(), 3 * 155730],
              [new Date(2023, 12, 28, 2).getTime(), 3 * 404898],
              [new Date(2024, 3, 28, 2).getTime(), 3 * 404898],
              [new Date(2024, 6, 28, 2).getTime(), 3 * 404898],
              [new Date(2024, 9, 28, 2).getTime(), 3 * 404898],
              [new Date(2024, 12, 28, 2).getTime(), 3 * 554398.8],
              [new Date(2025, 3, 28, 2).getTime(), 3 * 554398.8],
              [new Date(2025, 6, 28, 2).getTime(), 3 * 554398.8],
              [new Date(2025, 9, 28, 2).getTime(), 3 * 554398.8],
              [new Date(2025, 12, 28, 2).getTime(), 3 * 644099.28],
              [new Date(2026, 3, 28, 2).getTime(), 3 * 644099.28],
              [new Date(2026, 6, 28, 2).getTime(), 3 * 644099.28],
              [new Date(2026, 9, 28, 2).getTime(), 3 * 644099.28],
              [new Date(2026, 12, 28, 2).getTime(), 3 * 733799.76],
              [new Date(2027, 3, 28, 2).getTime(), 3 * 733799.76],
              [new Date(2027, 6, 28, 2).getTime(), 3 * 733799.76],
              [new Date(2027, 9, 28, 2).getTime(), 3 * 733799.76],
              [new Date(2027, 12, 28, 2).getTime(), 3 * 778650],
              [new Date(2028, 3, 28, 2).getTime(), 3 * 778650],
              [new Date(2028, 6, 28, 2).getTime(), 3 * 778650],
              [new Date(2028, 9, 28, 2).getTime(), 3 * 778650],
              [new Date(2028, 12, 28, 2).getTime(), 3 * 778650],
              [new Date(2029, 3, 28, 2).getTime(), 3 * 778650],
              [new Date(2029, 6, 28, 2).getTime(), 3 * 778650],
              [new Date(2029, 9, 28, 2).getTime(), 3 * 778650],
              [new Date(2029, 12, 28, 2).getTime(), 3 * 778650],
              [new Date(2030, 3, 28, 2).getTime(), 3 * 778650],
              [new Date(2030, 6, 28, 2).getTime(), 3 * 778650],
              [new Date(2030, 9, 28, 2).getTime(), 3 * 778650],
              [new Date(2030, 12, 28, 2).getTime(), 3 * 778650],
              [new Date(2031, 3, 28, 2).getTime(), 3 * 778650],
              [new Date(2031, 6, 28, 2).getTime(), 3 * 778650],
              [new Date(2031, 9, 28, 2).getTime(), 3 * 778650],
              [new Date(2031, 12, 28, 2).getTime(), 3 * 778650],
            ],
          },
          {
            name: 'Investment Tax Credit',
            data: [
              [new Date(2022, 3, 28, 2).getTime(), 3 * 0],
              [new Date(2022, 6, 28, 2).getTime(), 3 * 0],
              [new Date(2022, 9, 28, 2).getTime(), 3 * 0],
              [new Date(2022, 12, 28, 2).getTime(), 3 * 232700],
              [new Date(2023, 3, 28, 2).getTime(), 3 * 232700],
              [new Date(2023, 6, 28, 2).getTime(), 3 * 232700],
              [new Date(2023, 9, 28, 2).getTime(), 3 * 232700],
              [new Date(2023, 12, 28, 2).getTime(), 3 * 232700],
              [new Date(2024, 3, 28, 2).getTime(), 3 * 232700],
              [new Date(2024, 6, 28, 2).getTime(), 3 * 232700],
              [new Date(2024, 9, 28, 2).getTime(), 3 * 232700],
              [new Date(2024, 12, 28, 2).getTime(), 3 * 232700],
              [new Date(2025, 3, 28, 2).getTime(), 3 * 232700],
              [new Date(2025, 6, 28, 2).getTime(), 3 * 232700],
              [new Date(2025, 9, 28, 2).getTime(), 3 * 232700],
              [new Date(2025, 12, 28, 2).getTime(), 3 * 232700],
              [new Date(2026, 3, 28, 2).getTime(), 3 * 232700],
              [new Date(2026, 6, 28, 2).getTime(), 3 * 232700],
              [new Date(2026, 9, 28, 2).getTime(), 3 * 232700],
              [new Date(2026, 12, 28, 2).getTime(), 3 * 232700],
              [new Date(2027, 3, 28, 2).getTime(), 3 * 232700],
              [new Date(2027, 6, 28, 2).getTime(), 3 * 232700],
              [new Date(2027, 9, 28, 2).getTime(), 3 * 232700],
              [new Date(2027, 12, 28, 2).getTime(), 3 * 232700],
              [new Date(2028, 3, 28, 2).getTime(), 3 * 232700],
              [new Date(2028, 6, 28, 2).getTime(), 3 * 232700],
              [new Date(2028, 9, 28, 2).getTime(), 3 * 232700],
              [new Date(2028, 12, 28, 2).getTime(), 3 * 232700],
              [new Date(2029, 3, 28, 2).getTime(), 3 * 232700],
              [new Date(2029, 6, 28, 2).getTime(), 3 * 232700],
              [new Date(2029, 9, 28, 2).getTime(), 3 * 232700],
              [new Date(2029, 12, 28, 2).getTime(), 3 * 232700],
              [new Date(2030, 3, 28, 2).getTime(), 3 * 232700],
              [new Date(2030, 6, 28, 2).getTime(), 3 * 232700],
              [new Date(2030, 9, 28, 2).getTime(), 3 * 232700],
              [new Date(2030, 12, 28, 2).getTime(), 3 * 232700],
              [new Date(2031, 3, 28, 2).getTime(), 3 * 232700],
              [new Date(2031, 6, 28, 2).getTime(), 3 * 232700],
              [new Date(2031, 9, 28, 2).getTime(), 3 * 232700],
              [new Date(2031, 12, 28, 2).getTime(), 3 * 232700],
            ],
          },
        ],
      },
    }
  },
  mounted() {
    this.project = this.$store.getters.getProject(this.$route.params.address)
  },
}
</script>

<style scoped>
.metric {
  border-top: 1px solid;
  border-color: #ffffff24;
}
.label {
  width: 150px;
  display: inline-block;
}
</style>
